
import { defineComponent, ref } from "vue";
import useSampleCheckRule from "@/hooks/SpotCheckingConfiguration/useSampleCheckRule";
import { ColumnProps } from "ant-design-vue/es/table/interface";
type Key = ColumnProps["key"];
const tableWidth = window.innerWidth;
const tableHeight = window.innerHeight - 325;
import TableWrapper from "@/components/TableWrapper.vue";
import BasicModal from "./basicModal.vue";
import SpecialModal from "./specialModal.vue";
import { PlusCircleOutlined } from "@ant-design/icons-vue";
import GetColumnWidth from "@/utils/payment/getColumnWidth";
export default defineComponent({
  name: "payoutPreparation",
  components: {
    TableWrapper,
    BasicModal,
    SpecialModal,
    PlusCircleOutlined,
  },
  props: {},
  setup() {
    const {
      activeKey,
      basicColData,
      basicTblData,
      specialColData,
      specialTblData,
      saveClick,
      basicDelClick,
      specialDelClick,
      basicVisible,
      basicAddClick,
      basicSetChange,
      specialVisible,
      specialAddClick,
      specialSetChange,
    } = useSampleCheckRule();

    const basicTableWidth = ref(GetColumnWidth(basicColData));
    const specialTableWidth = ref(GetColumnWidth(specialColData));
    return {
      tableWidth,
      tableHeight,
      activeKey,
      basicColData,
      basicTblData,
      specialColData,
      specialTblData,
      saveClick,
      basicDelClick,
      specialDelClick,
      basicVisible,
      basicAddClick,
      basicSetChange,
      specialVisible,
      specialAddClick,
      specialSetChange,
      basicTableWidth,
      specialTableWidth
    };
  },
});
