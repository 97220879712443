
import { defineComponent, reactive, ref, watch } from "vue";
import { message } from "ant-design-vue";
import useModel from "@/hooks/useModel";
import * as API from "@/API/spotCheckingConfiguration";
export default defineComponent({
  name: "specialModel",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    type OfferType = {id: string;nameEn: string}
    const offerTypeList = ref<OfferType[]>([{
      id: '1',
      nameEn: 'SSSC'
    }, {
      id: '2',
      nameEn: 'Dealer Initiated Fleet'
    }, {
      id: '3',
      nameEn: 'BMBS Initiated Fleet'
    }, {
      id: '4',
      nameEn: 'Overall incentive'
    }])
    const _visible = useModel(props, "visible");
    type FormData = {
      programCode?: string;
      offerType?: string;
      dealerSamplePercentage?: string;
      vinSamplePercentage: string;
      minDealerSampleSum: string;
      minVinSampleSum: string;
    }
    const formState = reactive<FormData>({
      programCode: undefined,
      offerType: undefined,
      dealerSamplePercentage: '',
      vinSamplePercentage: '',
      minDealerSampleSum: '',
      minVinSampleSum: ''
    });
    const programCodeBlur = async (rule: any, value: string) => {
      if (!value) {
        return Promise.reject('Please input Program Code')
      }
      try { 
        const data = await API.getSpecialOfferTypeList({ programCodeOrId: formState.programCode })
        if (data && data.length > 0) {
          offerTypeList.value = data
          const index = data.findIndex((item: OfferType) => item.nameEn === formState.offerType)
          if (index === -1) formState.offerType = undefined
          return Promise.resolve()
        }
        offerTypeList.value = []
        formState.offerType = undefined
        return Promise.reject('Program Code does not exist')
      } catch {
        offerTypeList.value = []
        formState.offerType = undefined
        return Promise.reject('Program Code does not exist')
      }
    }
    const rules = reactive({
      // programCode: [
      //   {
      //     required: true,
      //     validator: programCodeBlur,
      //     trigger: "blur",
      //   }
      // ],
      dealerSamplePercentage: [
        {
          pattern:  /^([1-9]?\d)(\.\d{1,2})?$|100$/,
          message: 'VIN Sample % format error',
          trigger: "blur",
        }
      ],
      vinSamplePercentage: [
        {
          required: true,
          message: "Please input VIN Sample %",
          trigger: "blur",
        },
        {
          pattern:  /^([1-9]?\d)(\.\d{1,2})?$|100$/,
          message: 'VIN Sample % format error',
          trigger: "blur",
        }
      ],
      minDealerSampleSum: [
        {
          required: true,
          message: "Please input Min Dealer Sample",
          trigger: "blur",
        },
        {
          pattern: /^([1-9]\d*|0)$/,
          message: 'Min Dealer Sample format error',
          trigger: "blur",
        }
      ],
      minVinSampleSum: [
        {
          required: true,
          message: "Please input Min VIN Sample",
          trigger: "blur",
        },
        {
          pattern: /^([1-9]\d*|0)$/,
          message: 'Min VIN Sample format error',
          trigger: "blur",
        }
      ],
    });
    const handleCancel = () => {
      _visible.value = false;
    };
    const formRef = ref();
    const loading = ref(false)
    const handleConfirm = () => {
      loading.value = true
      formRef.value
        .validate()
        .then(() => {
          const params = {
            dealerSamplePercentage: formState.dealerSamplePercentage,
            programCode: formState.programCode,
            minDealerSampleSum: formState.minDealerSampleSum,
            minVinSampleSum: formState.minVinSampleSum,
            offerType: formState.offerType,
            vinSamplePercentage: formState.vinSamplePercentage
          }
          API.specialRuleCheck(params).then(res => {
            loading.value = false
            if (res.code === '0') {
              context.emit('on-set-change', formState)
            } else {
              message.warning(res.message)
            }
          }).catch(() => {
            loading.value = false
          });
        })
        .catch((error: any) => {
          loading.value = false
          console.log(error);
        });
    };
    const resetForm = () => {
      Object.assign(formState, {
        programCode: undefined,
        offerType: undefined,
        dealerSamplePercentage: '',
        vinSamplePercentage: '',
        minDealerSampleSum: '',
        minVinSampleSum: ''
      });
      formRef.value.resetFields();
    };
     // 关闭弹窗清空表单
    watch(_visible, (val) => {
      if (!val) {
        resetForm();
      }
    });
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      _visible,
      formState,
      rules,
      formRef,
      handleCancel,
      handleConfirm,
      loading,
      offerTypeList,
      programCodeBlur
    };
  },
});
