
import { defineComponent, reactive, ref, watch } from "vue";
import { message } from "ant-design-vue";
import useModel from "@/hooks/useModel";
import * as API from "@/API/spotCheckingConfiguration";
import BuSelector from "@/views/Payment/components/BuSelector.vue";
import EntitySelector from "@/views/Payment/components/EntitySelector.vue";

export default defineComponent({
  name: "basicModel",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BuSelector,
    EntitySelector,
  },
  setup(props, context) {
    type OfferType = {id: string;nameEn: string}
    type IncentiveType = {id: string;nameEn: string;offerType: OfferType[]}
    const incentiveTypeList = ref<IncentiveType[]>([])
    API.getIncentiveTypeDropDownSelectList().then((data: any) => {
      incentiveTypeList.value = data.incentiveCategory
    })
    const offerTypeList = ref<OfferType[]>()
    const _visible = useModel(props, "visible");
    type FormData = {
      entity?: string;
      bu?: string;
      incentiveTypeId?: string;
      incentiveType?: string;
      offerTypeId?: string;
      offerType?: string;
      dealerSamplePercentage?: string;
      vinSamplePercentage: string;
      minDealerSampleSum: string;
      minVinSampleSum: string;
    }
    const formState = reactive<FormData>({
      entity: undefined,
      bu: undefined,
      incentiveTypeId: undefined,
      incentiveType: undefined,
      offerTypeId: undefined,
      offerType: undefined,
      dealerSamplePercentage: '',
      vinSamplePercentage: '',
      minDealerSampleSum: '',
      minVinSampleSum: ''
    });
    const incentiveTypeChange = (val: string) => {
      const incentiveType = incentiveTypeList.value.find(item => item.id === val)
      formState.incentiveType = incentiveType ? incentiveType.nameEn : ''
    }
    const offerTypeChange = (val: string) => {
      const offerType = offerTypeList.value ? offerTypeList.value.find(item => item.id === val) : ''
      formState.offerType = offerType ? offerType.nameEn : ''
    }
    watch(() => formState.incentiveType, (newVal) => {
      if (newVal) {
        const index = incentiveTypeList.value.findIndex(item => newVal === item.nameEn)
        offerTypeList.value = incentiveTypeList.value[index].offerType
      } else {
        offerTypeList.value = []
      }
    })
    const rules = reactive({
      incentiveTypeId: [
        {
          required: true,
          message: "Please select Incentive Type",
          trigger: "change",
        },
      ],
      dealerSamplePercentage: [
        {
          pattern: /^([1-9]?\d)(\.\d{1,2})?$|100$/,
          message: 'VIN Sample % format error',
          trigger: "blur",
        }
      ],
      vinSamplePercentage: [
        {
          required: true,
          message: "Please input VIN Sample %",
          trigger: "blur",
        },
        {
          pattern: /^([1-9]?\d)(\.\d{1,2})?$|100$/,
          message: 'VIN Sample % format error',
          trigger: "blur",
        }
      ],
      minDealerSampleSum: [
        {
          required: true,
          message: "Please input Min Dealer Sample",
          trigger: "blur",
        },
        {
          pattern: /^([1-9]\d*|0)$/,
          message: 'Min Dealer Sample format error',
          trigger: "blur",
        }
      ],
      minVinSampleSum: [
        {
          required: true,
          message: "Please input Min VIN Sample",
          trigger: "blur",
        },
        {
          pattern: /^([1-9]\d*|0)$/,
          message: 'Min VIN Sample format error',
          trigger: "blur",
        }
      ],
    });
    const handleCancel = () => {
      _visible.value = false;
    };
    const formRef = ref();
    const loading = ref(false)
    const handleConfirm = () => {
      loading.value = true
      formRef.value
        .validate()
        .then(() => {
          const params = {
            bu: formState.bu,
            dealerSamplePercentage: formState.dealerSamplePercentage,
            entity: formState.entity,
            incentiveType: formState.incentiveType,
            minDealerSampleSum: formState.minDealerSampleSum,
            minVinSampleSum: formState.minVinSampleSum,
            offerType: formState.offerType,
            vinSamplePercentage: formState.vinSamplePercentage
          }
          API.basicRuleCheck(params).then(res => {
            loading.value = false
            if (res.code === '0') {
              context.emit('on-set-change', formState)
            } else {
              message.warning(res.message)
            }
          }).catch(() => {
            loading.value = false
          });
        })
        .catch((error: any) => {
          loading.value = false
          console.log(error);
        });
    };
    const resetForm = () => {
      Object.assign(formState, {
        entity: undefined,
        bu: undefined,
        incentiveTypeId: undefined,
        incentiveType: undefined,
        offerTypeId: undefined,
        offerType: undefined,
        dealerSamplePercentage: '',
        vinSamplePercentage: '',
        minDealerSampleSum: '',
        minVinSampleSum: ''
      });
      formRef.value.resetFields();
    };
     // 关闭弹窗清空表单
    watch(_visible, (val) => {
      if (!val) {
        resetForm();
      }
    });
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      _visible,
      formState,
      rules,
      formRef,
      handleCancel,
      handleConfirm,
      loading,
      incentiveTypeList,
      offerTypeList,
      incentiveTypeChange,
      offerTypeChange
    };
  },
});
