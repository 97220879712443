import { ref, computed, onMounted } from "vue";
import * as API from "@/API/spotCheckingConfiguration";
import useThousands from "@/utils/payment/useThousands";
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import { message } from 'ant-design-vue';

type Key = ColumnProps['key'];

export interface BasicTableData {
    id?: string;
    entity?: string;
    bu?: string;
    incentiveTypeId: string;
    incentiveType: string;
    offerTypeId: string;
    offerType?: string;
    dealerSamplePercentage?: string;
    vinSamplePercentage: string;
    minDealerSampleSum: string;
    minVinSampleSum: string;
    addId?: string;
}

export interface SpecialTableData {
    id?: string;
    programCode: string;
    offerType?: string;
    dealerSamplePercentage?: string;
    vinSamplePercentage: string;
    minDealerSampleSum: string;
    minVinSampleSum: string;
    addId?: string;
}

const useSampleCheckRule = () => {
    const activeKey = ref('basicRule')
    const basicColData = computed(() => {
        return [
            { title: "ENTITY", dataIndex: "entity", width: 120 },
            { title: "BU", dataIndex: "bu",width: 120 },
            { title: "Incentive Type", dataIndex: "incentiveType", width: 180 },
            { title: "Offer Type", dataIndex: "offerType",width: 120 },
            { title: "Dealer Sample %", dataIndex: "dealerSamplePercentage",width: 130, slots: { customRender: 'dealerSamplePercentage' }, align: 'right' },
            { title: "VIN Sample %", dataIndex: "vinSamplePercentage", width: 130,slots: { customRender: 'vinSamplePercentage' }, align: 'right' },
            { title: "Min Dealer Sample", dataIndex: "minDealerSampleSum", width: 150,customRender: useThousands(), align: 'right' },
            { title: "Min VIN Sample", dataIndex: "minVinSampleSum", width: 130,customRender: useThousands(), align: 'right' },
            { title: "Operation", dataIndex: "operation",slots: { customRender: 'operation' }, align: 'center',width: 90 }
        ]
    })
    const basicTblData = ref<BasicTableData[]>([])
    const specialColData = computed(() => {
        return [
            { title: "Program Code", dataIndex: "programCode" },
            { title: "Offer Type", dataIndex: "offerType" },
            { title: "Dealer Sample %", dataIndex: "dealerSamplePercentage", slots: { customRender: 'dealerSamplePercentage' }, align: 'right' },
            { title: "VIN Sample %", dataIndex: "vinSamplePercentage", slots: { customRender: 'vinSamplePercentage' }, align: 'right' },
            { title: "Min Dealer Sample", dataIndex: "minDealerSampleSum", customRender: useThousands(), align: 'right' },
            { title: "Min VIN Sample", dataIndex: "minVinSampleSum", customRender: useThousands(), align: 'right' },
            { title: "Operation", dataIndex: "operation",slots: { customRender: 'operation' }, align: 'center' }
        ]
    })
    const specialTblData = ref<SpecialTableData[]>([])
    const getBasicTable = () => {
        API.basicRuleSearch().then((data: any) => {
            basicTblData.value = data.filter((item: any) => item.entity !== 'DENZA')
        })
    }
    const getSpecialTable = () => {
        API.specialRuleSearch().then((data: any) => {
            specialTblData.value = data
        })
    }
    let basicDelIds: string[] = []
    let specialDelIds: string[] = []
    const saveClick = () => {
        const params = {
            idList: basicDelIds,
            spotCheckSampleCheckBasicRuleReqVOList: basicTblData.value
        }
        API.basicRulesSave(params).then(() => {
            basicTblData.value = []
            basicDelIds = []
            getBasicTable()
            const params = {
                idList: specialDelIds,
                spotCheckSampleCheckSpecialRuleReqVOList: specialTblData.value
            }
            API.specialRulesSave(params).then(() => {
                message.success('Successfully Save!')
                specialTblData.value = []
                specialDelIds = []
                getSpecialTable()
            })
        })
    }
    const basicDelClick = (record: BasicTableData, index: number) => {
        if (record.id) {
            basicDelIds.push(record.id)
        }
        basicTblData.value.splice(index, 1)
        message.success('Successfully Delete!')
    }
    const specialDelClick = (record: SpecialTableData, index: number) => {
        if (record.id) {
            specialDelIds.push(record.id)
        }
        specialTblData.value.splice(index, 1)
        message.success('Successfully Delete!')
    }
    const basicVisible = ref(false)
    const basicAddClick = () => {
        basicVisible.value = true
    }
    const basicSetChange = (formState: BasicTableData) => {
        basicTblData.value.push({
            bu: formState.bu,
            dealerSamplePercentage: formState.dealerSamplePercentage,
            entity: formState.entity,
            incentiveTypeId: formState.incentiveTypeId,
            incentiveType: formState.incentiveType,
            minDealerSampleSum: formState.minDealerSampleSum,
            minVinSampleSum: formState.minVinSampleSum,
            offerTypeId: formState.offerTypeId,
            offerType: formState.offerType,
            vinSamplePercentage: formState.vinSamplePercentage
        })
        message.success('Successfully Add!')
        basicVisible.value = false
    }
    const specialVisible = ref(false)
    const specialAddClick = () => {
        specialVisible.value = true
    }
    const specialSetChange = (formState: SpecialTableData) => {
        specialTblData.value.push({
            programCode: formState.programCode,
            dealerSamplePercentage: formState.dealerSamplePercentage,
            minDealerSampleSum: formState.minDealerSampleSum,
            minVinSampleSum: formState.minVinSampleSum,
            offerType: formState.offerType,
            vinSamplePercentage: formState.vinSamplePercentage
        })
        message.success('Successfully Add!')
        specialVisible.value = false
    }
    onMounted(() => {
        getBasicTable()
        getSpecialTable()
    })
    return {
        activeKey,
        basicColData,
        basicTblData,
        specialColData,
        specialTblData,
        saveClick,
        basicDelClick,
        specialDelClick,
        basicVisible,
        basicAddClick,
        basicSetChange,
        specialVisible,
        specialAddClick,
        specialSetChange
    }
}
export default useSampleCheckRule